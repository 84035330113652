.brands {
    border-radius: 8px;
    position: relative;

    &.info-is-open {
        .info-wrapper {
            transform: translateX(0px);
            width: 320px;
            max-width: 320px;
            flex: 0 0 320px;
        }

        .brand-list-left-side {
            width: calc(100vw - 320px - 32px);
        }
    }

    .info-wrapper {
        position: fixed;
        background: white;
        box-shadow: 0 2px 10px 0 rgba(0,0,0,0.33);
        width: 0px;
        height: 100vh;
        transition-duration: 0.25s;
        transform: translateX(64px);
        right: 0;
        overflow: auto;
        padding: 16px;
    }

    .info-box {
        overflow: auto;
        margin-bottom: 32px;

        tr {
            vertical-align: top;
            text-align: left;
        }

        th {
            background-color: none;
        }

        th:nth-last-child(1) {
            padding: 16px 0;
            font-size: 20px;
        }

        td:nth-child(2) {
            padding-left: 16px;
        }

        img {
            width: 100%;
            height: auto;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        a {
            text-decoration: none;
            color: #666;
        }


        .info-title {
            border-bottom: 1px solid #aaa;
            font-size: 20px;
            font-weight: 600;
            text-align: left;
            padding: 16px;
            padding-right: 32px;
            margin: -16px -16px 16px;
        }

        .close-info {
            position: absolute;
            top: 12px;
            right: 8px;
            appearance: none;
            background: #121212;
            height: 0px;
            width: 0px;
            padding: 12px;
            border-radius: 50%;
            border: none;
            cursor: pointer;
            transition-duration: 0.3s;


            &:before,
            &:after {
                content: "";
                display: block;
                position: absolute;
                width: 9px;
                height: 1px;
                background-color: #fff;
                transform: translateX(-50%) rotate(45deg);
            }
            &:after {
                transform: translateX(-50%) rotate(-45deg);
            }

            &:hover {
                background: #555;
            }
        }
    }

    .search-wrapper {
        position: relative;
    }

    .search-field {
        margin-bottom: 16px;
        padding: 8px;
        border-radius: 4px;
        box-shadow: inset 0 1px 2px 1px rgba(0,0,0,0.25);
        border: none;
        width: calc(100% - 82px);
        font-weight: 600;
        font-size: 16px;
    }

    .search-count {
        display: block;
        position: absolute;
        top: 9px;
        right: 42px;
        color: #121212;
    }

    .brand-models {
        display: flex;
    }

    .brand-wrapper {
        flex: 1;
    }

    .brand-scroll-wrapper {
        flex: 1;
        overflow: auto;
        position: relative;
        // padding-top: 32px;
    }

    .brand-scroll-shadow {
        box-shadow: inset 0 0 10px 10px #121212;
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .brand-list-wrapper {
        display: flex;
    }

    .brand-list-left-side {
        margin: 32px 0;
        position: relative;
        min-width: 0;
        overflow: hidden;
        transition-duration: 0.25s;
        width: 100vw;
    }

    .brand-list {
        display: block;

        .brand-group {
            display: flex;
            // overflow: auto;

            &:hover .car-box {
                .show-decade,
                &::before,
                &::after {
                    opacity: 1;
                }
            }
        }
        .brand-box {
            max-width: 128px;
            min-width: 128px;
            // padding-top: 32px;
            flex: 1;

            @media screen and (min-width: 1024px) {
                    min-width: 192px;
                    max-width: 192px;
            }

            img {
                width: 100%;
            }
        }
        .car-box {
            min-width: 128px;
            max-width: 128px;
            flex: 0;
            position: relative;

            @media screen and (min-width: 1024px) {
                min-width: 192px;
                max-width: 192px;
            }

            img {
                width: 100%;
            }

            &.clickable {
                cursor: pointer;
                transition-duration: 0.3s;
                transition-property: background;
                border-radius: 50%;

                &:hover {
                    background: rgba(0,0,0,0.25);
                }
            }

            .show-decade {
                opacity: 0;
                color: white;
                position: absolute;
                top: 0px;
                left: 3px;
                font-size: 10px;
                background-color: white;
                color: #121212;
                padding: 2px 6px;
                border-radius: 12px;
                transition-property: transform opacity;
                transition-duration: 0.33s;
            }

            &::before {
                opacity: 0;
                width: 100%;
                height: 1px;
                top: 8px;
                position: absolute;
                content: "";
                display: block;
                background-color: white;
                transition-property: transform opacity;
                transition-duration: 0.33s;
            }

            &:first-child::before {
                width: calc(100% - 5px);
                left: 5px;
                height: 1px;
                top: 8px;
                position: absolute;
                content: "";
                display: block;
                background-color: white;
                transition-property: transform opacity;
                transition-duration: 0.33s;
            }

            &:last-child::after {
                opacity: 0;
                width: 1px;
                height: 11px;
                right: 0;
                top: 3px;
                position: absolute;
                content: "";
                display: block;
                background-color: white;
            }
        }
    }
}
