.search-dropdown {
    padding: 8px 0;
    border-radius: 4px;
    background: #ddd;
    position: absolute;
    top: 10px;
    width: calc(100% - 64px);
    margin: 32px;
    z-index: 10;
    display: none;

    &.show-dropdown {
        display: flex;
    }

    ul {
        list-style: none;
        text-align: left;
        padding: 0;
        margin: 0;
        width: 100%;

        li {
            padding: 8px;
            display: flex;

            &:hover, &.active {
                background: #eee;
            }

            &:last-child {
                margin: 0;
            }

            div {
                display: flex;
                overflow: auto;
            }
        }

        span {
            padding: 4px 8px;
            background: #bbb;
            border-radius: 4px;
            margin: 0 4px;
            white-space: nowrap;
            cursor: pointer;

            &.key {
                background: transparent;
                opacity: 0.5;
            }
        }
    }

    label {
        margin: 8px;
        display: block;
    }

    > div {
        text-align: left;
    }
}
